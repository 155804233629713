import apiFun from '../api'

export default function ({ store, $axios, $config }, inject) {
  const api = $axios.create({
    baseURL: 'https://api.ltng.app',
    params: {
      account_id: store.state.account_id,
      mgmt: 1,
    },
  })

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config

      if (
        error &&
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true
        api
          .post(`${$config.auth0.domain}/oauth/token`, {
            grant_type: 'refresh_token',
            client_id: $config.auth0.clientId,
            refresh_token: store.getters.refresh_token,
          })
          .then((res) => {
            if (res.status === 201 || res.status === 200) {
              store.commit('changeToken', res.data.access_token)
              return api(originalRequest)
            }
          })
      }

      return Promise.reject(error)
    }
  )

  api.onRequest((config) => {
    api.defaults.headers.common.Authorization = 'Bearer ' + store.getters.token
    return config
  })

  inject('Api', {
    ...apiFun(api),
  })
}
