import { render, staticRenderFns } from "./ProfileCard.vue?vue&type=template&id=7d26122e&scoped=true&"
import script from "./ProfileCard.vue?vue&type=script&lang=js&"
export * from "./ProfileCard.vue?vue&type=script&lang=js&"
import style0 from "./ProfileCard.vue?vue&type=style&index=0&id=7d26122e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d26122e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarElementsAvatar: require('/home/travis/build/Polumathes/LTNG-Transact-Frontend/components/SidebarElements/Avatar.vue').default})
