
import vClickOutside from 'v-click-outside'
export default {
  name: 'PlatformModal',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },

    openCms() {
      const aes = require('crypto-js/aes')
      const auth = aes.encrypt(
        JSON.stringify({
          access_token: this.$store.state.token,
          account_id: this.$store.state.account_id,
          user_id: this.$store.state.user_id,
          refresh_token: this.$store.state.refresh_token,
        }),
        this.$config.salt
      )
      window.open(
        `https://cms.ltng.app/cms/content-management?auth=${encodeURIComponent(
          auth.toString()
        )}`
      )
    },
  },
}
