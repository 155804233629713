
import { FadeTransition } from 'vue2-transitions'

export default {
  name: 'Platform',

  components: {
    FadeTransition,
  },

  data() {
    return {
      isModalOpen: false,
    }
  },

  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen
    },
  },
}
