
export default {
  name: 'Badge',

  props: {
    value: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    computedClass() {
      return {}
    },
  },
}
