export default function (axios) {
  return {
    getProductDetails(id, params) {
      return axios
        .get(`/modules/transact/products/${id}`, params)
        .then((res) => res.data)
    },

    updateProduct(id, body, params) {
      return axios
        .put(`/modules/transact/products/${id}`, body, params)
        .then((res) => res.data)
    },

    deleteProduct(id, params) {
      return axios
        .delete(`/modules/transact/products/${id}`, params)
        .then((res) => res.data)
    },

    getProducts(params) {
      return axios
        .get('/modules/transact/products', params)
        .then((res) => res.data)
    },

    createProduct(body, params) {
      return axios
        .post('/modules/transact/products', body, params)
        .then((res) => res)
    },

    getProductType(id, params) {
      return axios
        .get(`/modules/transact/product-types/${id}`, params)
        .then((res) => res.data)
    },

    updateProductType(id, body, params) {
      return axios
        .put(`/modules/transact/product-types/${id}`, body, params)
        .then((res) => res.data)
    },

    getProductTypes(params) {
      return axios
        .get(`/modules/transact/product-types`, params)
        .then((res) => res.data.results)
    },

    createProductTypes(body, params) {
      return axios
        .post('/modules/transact/product-types', body, params)
        .then((res) => res.data)
    },

    getProductVariants(productId, params = {}) {
      return axios
        .get(`/modules/transact/product-variants`, {
          params: {
            product_id: productId,
            ...params.params,
          },
          ...params,
        })
        .then((res) => res.data.results)
    },

    createProductVariants(body, params) {
      return axios
        .post('/modules/transact/product-variants', body, params)
        .then((res) => res.data)
    },

    updateProductVariant(id, body, params) {
      return axios
        .put(`/modules/transact/product-variants/${id}`, body, params)
        .then((res) => res.data)
    },

    deleteProductVariant(id, params) {
      return axios
        .delete(`/modules/transact/product-variants/${id}`, params)
        .then((res) => res.data)
    },

    getProductVariant(id, params) {
      return axios
        .get(`/modules/transact/product-variants/${id}`, params)
        .then((res) => res.data)
    },
  }
}
