export default function (axios) {
  return {
    getClientConfig(id, params) {
      return axios
        .get(`/usersconfig${id ? `?id=${id}` : ''}`, { params })
        .then((res) => res.data)
    },

    updateClientConfig(id, payload) {
      return axios
        .put(`/usersconfig${id ? `?id=${id}` : ''}`, payload)
        .then((res) => res.data)
    },
  }
}
