export const state = () => ({})

const getters = {}

export const actions = {}

const mutations = {}

export default {
  namespaced: true, // to add the module name before getter
  state,
  getters,
  actions,
  mutations,
}
