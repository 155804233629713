
export default {
  name: 'ProfileCard',

  props: {
    value: {
      type: Number,
      default: 0,
    },
    // title: {
    //   type: String,
    //   default: 'Title',
    // },
    // subtitle: {
    //   type: String,
    //   default: 'Subtitle',
    // },
  },
  computed: {
    imageUrl() {
      return this.$store.getters.user_info.picture
    },

    title() {
      return this.$store.getters.user_info.nickname
    },

    subtitle() {
      return this.$store.getters.user_info.email
    },
  },
}
