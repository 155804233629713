
export default {
  name: 'SidebarItem',

  props: {
    link: {
      type: Object,
      default: () => {
        return {
          name: '',
          label: '',
          icon: '',
        }
      },
    },
  },

  data() {
    return {}
  },
}
