export default function (axios) {
  return {
    getAccessToken(payload) {
      return axios
        .post('https://dev-c9dtvtwx.us.auth0.com/passwordless/start', payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((r) => r.data)
    },

    loginUsingToken(payload) {
      return axios
        .post('https://dev-c9dtvtwx.us.auth0.com/oauth/token', payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((r) => r.data)
    },

    getUserProfile() {
      return axios
        .get('https://dev-c9dtvtwx.us.auth0.com/oauth/userinfo')
        .then((r) => r.data)
    },
  }
}
