export const state = () => ({
  token: null,
  account_id: null,
  user_id: null,
  refresh_token: null,
  user_info: {},
  clientConfig: {},
})

const getters = {
  token: (state) => state.token,
  account_id: (state) => state.account_id,
  refresh_token: (state) => state.refresh_token,
  user_id: (state) => state.user_id,
  user_info: (state) => state.user_info,
  clientConfig: (state) => state.clientConfig,
}

export const actions = {
  async getUserInfo({ commit, dispatch }) {
    const userInfo = await this.$Api.auth.getUserProfile()
    await commit('setUserInfo', userInfo)
    await dispatch('getCloudflareImagesBaseUrl')
  },

  async getClientConfig({ commit }) {
    const { results } = await this.$Api.ltng.getClientConfig()

    results.forEach((result) =>
      commit('setClientConfig', {
        id: result.id.split(':')[1],
        value: result.value,
      })
    )
  },
}

const mutations = {
  changeToken(state, token) {
    state.token = token
  },
  changeRefreshToken(state, refreshToken) {
    state.refresh_token = refreshToken
  },
  changeAccountId(state, id) {
    state.account_id = id
  },
  changeUserId(state, id) {
    state.user_id = id
  },
  setUserInfo(state, payload) {
    state.user_info = payload
  },
  setClientConfig(state, payload) {
    state.clientConfig = {
      ...state.clientConfig,
      [payload.id]: payload.value,
    }
  },
}

export default {
  namespaced: true, // to add the module name before getter
  state,
  getters,
  actions,
  mutations,
}
