export default async (context) => {
  const workbox = await window.$workbox

  if (!workbox) {
    console.debug("Workbox couldn't be loaded.")
    return
  }

  workbox.addEventListener('activate', (event) => {
    console.log('activate sw event', event)
  })

  workbox.addEventListener('installed', (event) => {
    if (!event.isUpdate) {
      console.debug('The PWA is on the latest version.')
      return
    }

    const handleRefresh = () => {
      // Clear SW caches
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName)
        })
      })

      window.location.reload()
    }

    context.$Toast
      .fire({
        position: 'bottom-end',
        showConfirmButton: true,
        confirmButtonText: 'Refresh',
        timer: null,
        text: 'A new version is available. Refresh to update.',
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleRefresh()
        }
      })
  })
}
