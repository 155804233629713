
export default {
  name: 'Avatar',

  props: {
    imageUrl: {
      type: String,
      default: null,
    },

    value: {
      type: Number,
      default: 0,
    },
  },
}
