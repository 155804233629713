import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=f4d82d70&scoped=true&"
import script from "./Sidebar.vue?vue&type=script&lang=js&"
export * from "./Sidebar.vue?vue&type=script&lang=js&"
import style0 from "./Sidebar.vue?vue&type=style&index=0&id=f4d82d70&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4d82d70",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarElementsLogo: require('/home/travis/build/Polumathes/LTNG-Transact-Frontend/components/SidebarElements/Logo.vue').default,SidebarItem: require('/home/travis/build/Polumathes/LTNG-Transact-Frontend/components/SidebarElements/SidebarItem.vue').default,SidebarElementsProfileCard: require('/home/travis/build/Polumathes/LTNG-Transact-Frontend/components/SidebarElements/ProfileCard.vue').default})
