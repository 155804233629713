export default async function (
  { $Toast, store, $config, query, $cookie },
  inject
) {
  let auth = $cookie.get('ltng.auth')
  let account = $cookie.get('ltng.account')

  try {
    if (auth && account) {
      auth = JSON.parse(auth)
      account = JSON.parse(account)

      await storeAuthData(store, {
        access_token: auth.access_token,
        refresh_token: auth.refresh_token,
        user_id: account.user_id,
        account_id: account.account_id,
      })
    } else if (query.auth) {
      const crypto = require('crypto-js')
      const auth = JSON.parse(
        crypto.AES.decrypt(
          decodeURIComponent(query.auth),
          $config.salt
        ).toString(crypto.enc.Utf8)
      )

      await storeAuthData(store, auth)
    }

    $Toast.fire({
      icon: 'success',
      title: 'Signed in successfully',
    })
  } catch (error) {
    console.log(error)
  }
}

async function storeAuthData(
  store,
  // eslint-disable-next-line camelcase
  { access_token, refresh_token, user_id, account_id }
) {
  store.commit('changeToken', access_token)
  store.commit('changeAccountId', account_id)
  store.commit('changeUserId', user_id)
  store.commit('changeRefreshToken', refresh_token)

  return await store.dispatch('getUserInfo')
}
