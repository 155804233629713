export default function (context, inject) {
  inject('helpers', {
    cfImage: (imageId, variant = 'public') =>
      `${context.store.state.clientConfig.CF_IMAGES_PUBLIC_URL}${imageId}/${variant}`,
    shortId: (id) => {
      if (id) return id.substr(-7)

      return ''
    },
  })
}
