
export default {
  name: 'Sidebar',

  data() {
    return {
      items: [
        {
          label: 'dashboard',
          name: 'index',
          icon: 'icon/home',
        },

        {
          label: 'orders',
          name: 'orders',
          icon: 'icon/orders',
        },
        {
          label: 'customers',
          name: 'customers',
          icon: 'icon/customers',
        },
        {
          label: 'products',
          name: 'products',
          icon: 'icon/products',
        },
        // {
        //   label: 'subscriptions',
        //   name: 'subscriptions',
        //   icon: 'icon/subscriptions',
        // },
        {
          label: 'reports',
          name: 'reports',
          icon: 'icon/reports',
        },
        // {
        //   label: 'marketing',
        //   name: 'marketing',
        //   icon: 'icon/marketing',
        // },
        // {
        //   label: 'abandoned cart',
        //   name: 'abandonedCart',
        //   icon: 'icon/empty-cart',
        // },
        // {
        //   label: 'coupons',
        //   name: 'coupons',
        //   icon: 'icon/coupons',
        // },
        // {
        //   label: 'automations',
        //   name: 'automations',
        //   icon: 'icon/automations',
        // },
        {
          label: 'shop configuration',
          name: 'shopConfiguration',
          icon: 'icon/configuration',
        },
      ],
    }
  },
}
