export default function (axios) {
  return {
    getInventoryItem(id, params) {
      return axios
        .get(`/modules/transact/inventory-items/${id}`, { params })
        .then((res) => res.data)
    },

    updateInventoryItem(id, params) {
      return axios
        .put(`/modules/transact/inventory-items/${id}`, params)
        .then((res) => res.data)
    },

    getInventoryItems(params) {
      return axios
        .get('/modules/transact/inventory-items', { params })
        .then((res) => res.data)
    },

    createInventoryItem(body, params) {
      return axios
        .post('/modules/transact/inventory-items', body, params)
        .then((res) => res.data)
    },
  }
}
