import auth from './auth'
import customers from './customers'
import products from './products'
import orders from './orders'
import inventory from './inventory'
import shipments from './shipments'
import dashboard from './dashboard'
import reports from './reports'
import ltng from './ltng'

export default function (axios) {
  return {
    customers: customers(axios),
    products: products(axios),
    orders: orders(axios),
    inventory: inventory(axios),
    shipments: shipments(axios),
    auth: auth(axios),
    dashboard: dashboard(axios),
    reports: reports(axios),
    ltng: ltng(axios),
    $client: axios,
  }
}
