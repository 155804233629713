
import { SlideXLeftTransition } from 'vue2-transitions'

export default {
  name: 'Default',

  components: {
    SlideXLeftTransition,
  },

  async created() {
    await this.$store.dispatch('getClientConfig')
  },
}
