export default function (axios) {
  return {
    getOrderDetails(id, params = {}) {
      return axios
        .get(`/modules/transact/orders/${id}`, params)
        .then((res) => res.data)
    },

    updateOrder(id, body, params = {}) {
      return axios
        .put(`/modules/transact/orders/${id}`, body, params)
        .then((res) => res.data)
    },

    getOrders(params) {
      return axios
        .get('/modules/transact/orders', params)
        .then((res) => res.data)
    },

    createOrder(body, params = {}) {
      return axios
        .post('/modules/transact/orders', body, params)
        .then((res) => res.data)
    },

    deleteOrder(id, params = {}) {
      return axios
        .delete(`/modules/transact/orders/${id}`, params)
        .then((res) => res.data)
    },

    getOrderShipment(id, params) {
      return axios
        .get(`/modules/transact/order-shipments/${id}`, params)
        .then((res) => res.data)
    },

    getOrderShipmentDetails(id, params) {
      return axios
        .get(`/modules/transact/order-shipments/?order_id=${id}`, params)
        .then((res) => res.data)
    },

    updateOrderShipment(id, body, params) {
      return axios
        .post(`/modules/transact/order-shipments/?order_id=${id}`, body, params)
        .then((res) => res.data)
    },

    getOrderShipments(params) {
      return axios
        .get('/modules/transact/order-shipments', params)
        .then((res) => res.data)
    },

    deleteOrderShipment(id, params) {
      return axios
        .delete(`/modules/transact/order-shipments/${id}`, params)
        .then((res) => res.data)
    },

    createOrderShipment(body, params) {
      return axios
        .post('/modules/transact/order-shipments', body, params)
        .then((res) => res.data)
    },

    getOrderAddress(id, body, params) {
      return axios
        .get(`/modules/transact/order-addresses/${id}`, body, params)
        .then((res) => res.data)
    },

    updateOrderAddress(id, body, params) {
      return axios
        .put(`/modules/transact/order-addresses/${id}`, body, params)
        .then((res) => res.data)
    },

    getOrderAddresses(params) {
      return axios
        .get('/modules/transact/order-addresses', params)
        .then((res) => res.data.results)
    },

    createOrderAddress(body, params = {}) {
      return axios
        .post('/modules/transact/order-addresses', body, params)
        .then((res) => res.data)
    },
  }
}
