export default function (axios) {
  return {
    getReceivedShipment(id, params) {
      return axios
        .get(`/modules/transact/received-shipments/${id}`, params)
        .then((res) => res.data)
    },

    updateReceivedShipment(id, body, params) {
      return axios
        .put(`/modules/transact/received-shipments/${id}`, body, params)
        .then((res) => res.data)
    },

    getReceivedShipments() {
      return axios.get('/modules/transact/received-shipments')
    },

    createReceivedShipment(body, params) {
      return axios
        .post('/modules/transact/received-shipments', body, params)
        .then((res) => res.data)
    },

    getShippingMethod(id, params) {
      return axios
        .get(`/modules/transact/shipping-methods/${id}`, params)
        .then((res) => res.data)
    },

    updateShippingMethod(id, body, params) {
      return axios
        .put(`/modules/transact/shipping-methods/${id}`, body, params)
        .then((res) => res.data)
    },

    getShippingMethods(params = {}) {
      return axios
        .get('/modules/transact/shipping-methods', params)
        .then((res) => res.data)
    },

    createShippingMethods(body, params) {
      return axios
        .post('/modules/transact/shipping-methods', body, params)
        .then((res) => res.data)
    },
  }
}
